<template >
  <div class="con" v-if="!$store.state.pcshow">
    <img src="../../assets/img/index/companyIntro15.png" alt="" />
    <div class="yj">
      <h4><span></span>生命健康集群平台</h4>
      <p>集团于今年二月与连云港经济技术开发区签订了生命健康集群平台的投资项目。该项目通过整体运营服务，数字化服务创新，行业资源导 入，产教研销四维一体全产业链生态搭建、金融配套服务等核心要素，为医药企业提供科学、系统的企业孵化服务，项目达产后年营业收 入15亿元，税收6000万元人民币。项目前期总投资1600万元人民币。</p>
      
    </div>
  </div>
  <div class="main" v-else>
    
  </div>
</template>

<script>
export default {};
</script>

<style scoped lang="stylus" >
  @media screen and (max-width: 960px) {
    .con{
      width: 100%;
      background: #F5F5F5;
      
      >img{
        display: block
        width:7.5rem;
        height:3rem;
      }
      .yj{
        display: block;
        width: calc( 100% - 0.35rem );
        background: #ffffff;
        padding-left:0.35rem;
        padding-bottom:0.38rem;
        border-top: 0.3rem solid #e5e5e5;
        border-bottom: 0.4rem solid #e5e5e5;

        >h4{
          font-size: 0.26rem;
          font-family: Source Han Sans CN;
          font-weight: 500;
          color: #333333;
          line-height: 0.39rem;
          padding-top:0.35rem;
          padding-bottom:0.1rem;

          span{
            display: inline-block;
            width: 0.03rem;
            height: 0.23rem;
            background: #0085D0;
            margin-right:0.07rem;
          }

        }

        p{
            width: 6.8rem;
            font-size: 0.24rem;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #333333;
            line-height: 0.4rem;
        }
        // :nth-child(2){
        //     margin-bottom: 0.2rem;
        // }
      }
      
    }
  }
  @media screen and (min-width: 960px) { 
    
  }
</style>